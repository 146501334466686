import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const GoldGbp = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg
      {...props}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 511.995"
    >
      <path
        fill="#ECCA43"
        fillRule="nonzero"
        d="M256 0c70.685 0 134.689 28.659 181.015 74.984C483.341 121.306 512 185.311 512 256c0 70.684-28.659 134.689-74.985 181.015-46.326 46.322-110.33 74.98-181.015 74.98-70.685 0-134.689-28.658-181.015-74.98C28.659 390.689 0 326.684 0 256c0-70.689 28.659-134.694 74.985-181.016C121.307 28.659 185.311 0 256 0z"
      />
      <ellipse fill="#F7E259" cx="256" cy="255.998" rx="250.992" ry="250.991" />
      <path
        fill="#F8D548"
        d="M503.753 215.692A252.691 252.691 0 01506.989 256c0 138.614-112.371 250.988-250.989 250.988S5.007 394.614 5.007 256c0-21.858 2.801-43.056 8.051-63.271l246.435 183.476 244.26-160.513z"
      />
      <path
        fill="#D7925B"
        fillRule="nonzero"
        d="M256 58.922c54.414 0 103.688 22.061 139.353 57.725 35.664 35.661 57.725 84.935 57.725 139.349 0 54.414-22.061 103.688-57.725 139.352-35.665 35.664-84.939 57.726-139.353 57.726-54.414 0-103.688-22.062-139.349-57.726-35.664-35.664-57.725-84.938-57.725-139.352s22.061-103.688 57.725-139.349C152.312 80.983 201.586 58.922 256 58.922z"
      />
      <path
        fill="#EDA140"
        d="M256 63.929c106.076 0 192.071 85.994 192.071 192.067 0 106.076-85.995 192.071-192.071 192.071-106.073 0-192.067-85.995-192.067-192.071 0-106.073 85.994-192.067 192.067-192.067z"
      />
      <path
        fill="#C26A34"
        fillRule="nonzero"
        d="M251.309 241.14h39.342v31.066h-34.784c-.442 7.302-1.77 13.895-4.027 19.781-1.814 4.47-4.823 9.116-9.072 13.94 4.824-1.062 8.984-1.593 12.436-1.593 4.425 0 10.178.84 17.258 2.522 9.692 2.213 17.437 3.319 23.234 3.319 4.735 0 8.85-.398 12.346-1.195 3.54-.796 8.143-2.478 13.808-5.045l17.656 38.767c-8.54 4.248-16.063 7.213-22.569 8.85-6.461 1.638-13.409 2.434-20.843 2.434-8.143 0-17.392-1.416-27.88-4.249-13.718-3.761-22.215-5.929-25.534-6.549-3.275-.575-6.815-.885-10.621-.885-11.152 0-23.454 3.894-36.907 11.683l-16.816-36.907c18.232-13.011 27.348-26.021 27.348-39.032 0-.708-.088-2.655-.265-5.841h-27.083V241.14h19.382c-3.274-11.373-5.177-18.011-5.575-19.826-.531-2.743-.797-6.151-.797-10.222 0-10.798 2.877-20.49 8.629-29.075 5.709-8.585 13.276-14.824 22.658-18.675 9.382-3.894 21.994-5.841 37.881-5.841 14.869 0 26.641 1.681 35.314 5 8.674 3.32 16.02 8.851 21.994 16.551 6.019 7.745 10.134 17.082 12.391 28.057l-49.652 7.744c-2.478-9.204-5.355-15.312-8.585-18.321-3.231-3.009-6.904-4.514-11.019-4.514-5.001 0-9.028 1.638-12.126 4.913-3.097 3.275-4.647 7.921-4.647 13.939 0 3.231.31 6.284.93 9.249.575 3.01 2.655 10.001 6.195 21.021z"
      />
      <path
        fill="#F3DC6B"
        fillRule="nonzero"
        d="M245.477 235.307h39.341v31.066h-34.783c-.442 7.302-1.77 13.896-4.027 19.782-1.814 4.469-4.823 9.116-9.072 13.94 4.824-1.063 8.984-1.593 12.435-1.593 4.426 0 10.179.84 17.259 2.522 9.692 2.212 17.436 3.319 23.233 3.319 4.735 0 8.851-.399 12.347-1.195 3.54-.797 8.143-2.479 13.807-5.045l17.657 38.766c-8.54 4.249-16.064 7.213-22.569 8.85-6.461 1.638-13.409 2.435-20.843 2.435-8.143 0-17.392-1.416-27.88-4.249-13.719-3.761-22.216-5.929-25.534-6.549-3.275-.575-6.815-.885-10.621-.885-11.152 0-23.455 3.894-36.908 11.683l-16.816-36.908c18.233-13.01 27.349-26.021 27.349-39.031 0-.708-.089-2.655-.266-5.842h-27.083v-31.066h19.383c-3.275-11.373-5.178-18.011-5.576-19.825-.531-2.744-.796-6.151-.796-10.222 0-10.799 2.876-20.49 8.629-29.075 5.709-8.585 13.276-14.825 22.658-18.675 9.382-3.894 21.994-5.841 37.881-5.841 14.869 0 26.64 1.681 35.314 5s16.02 8.851 21.994 16.551c6.018 7.744 10.134 17.082 12.391 28.057l-49.653 7.744c-2.478-9.205-5.354-15.312-8.585-18.321-3.23-3.009-6.903-4.514-11.019-4.514-5 0-9.027 1.638-12.125 4.912-3.098 3.275-4.647 7.922-4.647 13.94 0 3.23.31 6.284.93 9.249.575 3.009 2.655 10.001 6.195 21.02z"
      />
    </EmblemSvg>
  );
};
