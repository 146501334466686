import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const Hangseng = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg {...props} viewBox="0 0 64 64">
      <circle cx="31.9" cy="32" r="30" fill="#ed4c5c"></circle>
      <path
        d="M29.9 27.8c.7-1.8 2.4-2.1 3.3-4.4c.4-1-.3-3 .1-4c.9-2.3 3.2-1.9 3.2-1.9c-4.1-1.5-8.7.6-10.2 4.6c-1.5 4 .6 8.5 4.7 9.9c-1.1-1.2-1.7-2.6-1.1-4.2"
        fill="#ffffff"
      ></path>
      <circle cx="30.3" cy="21.3" r=".9" fill="#ed4c5c"></circle>
      <path
        d="M26.5 31.8c-1.5-1.2-1.3-2.9-3.3-4.4c-.9-.7-3-.6-3.9-1.3c-2-1.5-.8-3.6-.8-3.6c-2.7 3.4-2.1 8.3 1.4 10.9c3.4 2.6 8.4 2 11.1-1.3c-1.6.6-3.1.7-4.5-.3"
        fill="#ffffff"
      ></path>
      <circle cx="20.8" cy="29.5" r=".9" fill="#ed4c5c"></circle>
      <path
        d="M29.4 36.2c-1.6 1.1-3.2.3-5.3 1.7c-.9.6-1.5 2.6-2.4 3.2c-2.1 1.4-3.8-.3-3.8-.3c2.4 3.6 7.4 4.5 11 2.1c3.6-2.4 4.6-7.2 2.2-10.8c.1 1.7-.3 3.1-1.7 4.1"
        fill="#ffffff"
      ></path>
      <path
        d="M25 40.3c.3-.4.9-.5 1.3-.2s.5.9.2 1.3s-.9.5-1.3.2s-.5-.9-.2-1.3"
        fill="#ed4c5c"
      ></path>
      <path
        d="M34.5 34.9c.5 1.8-.7 3.1 0 5.5c.3 1 2 2.2 2.3 3.3c.7 2.4-1.5 3.4-1.5 3.4c4.2-1.2 6.6-5.5 5.4-9.6c-1.2-4.1-5.6-6.5-9.8-5.3c1.8.3 3.1 1 3.6 2.7"
        fill="#ffffff"
      ></path>
      <path
        d="M37.6 40.2c-.3-.4-.2-1 .2-1.3c.4-.3 1-.2 1.3.2c.3.4.2 1-.2 1.3c-.5.4-1 .3-1.3-.2"
        fill="#ed4c5c"
      ></path>
      <path
        d="M34.8 29.7c2 .1 2.8 1.6 5.3 1.7c1.1 0 2.8-1.2 3.9-1.2c2.5.1 2.8 2.4 2.8 2.4c.2-4.3-3.3-7.9-7.6-8c-4.4-.1-8 3.2-8.2 7.5c.9-1.5 2.1-2.5 3.8-2.4"
        fill="#ffffff"
      ></path>
      <path
        d="M41.4 28.5c-.5.2-1-.1-1.2-.6c-.2-.5.1-1 .6-1.2c.5-.2 1 .1 1.2.6c.2.5-.1 1.1-.6 1.2"
        fill="#ed4c5c"
      ></path>
    </EmblemSvg>
  );
};
