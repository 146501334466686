const locators = {
  checkboxWrapper: 'checkbox-wrapper',
  codeWindow: 'code-window',
  codeWindowContent: 'code-window-content',
  copyIcon: 'copy-icon',
  connectionModalSuccess: 'connection-modal-success',
  connectionSuccessHostname: 'connection-success-hostname',
  connectionModalApprove: 'connection-modal-approve',
  connectionModalAccessListTitle: 'connection-modal-access-list-title',
  connectionModalAccessListAccess: 'connection-modal-access-list-access',
  connectionModalDenyButton: 'connection-modal-deny-button',
  connectionModalApproveButton: 'connection-modal-approve-button',
  connectionModal: 'connection-modal',
  collapsiblePanel: 'collapsible-panel',
  collapsiblePanelButton: 'collapsible-panel-button',
  collapsiblePanelContent: 'collapsible-panel-content',
  copyWithCheck: 'copy-with-check',
  dAppHostname: 'dapp-hostname',
  errorMessage: 'input-error-text',
  frame: 'frame',
  hideIcon: 'hide-icon',
  modalHeader: 'modal-header',
  mnemonicContainerShown: 'mnemonic-container-shown',
  mnemonicContainerHidden: 'mnemonic-container-hidden',
  mnemonicContainerMnemonic: 'mnemonic-container-mnemonic',
  pageBack: 'page-back',
  dropdownArrow: 'dropdown-arrow',
  leftRightArrows: 'left-right-arrows',
  listItem: 'list-item',
  list: 'list',
  mnemonicContainer: 'mnemonic-container',
  navButton: 'nav-button',
  linkActive: 'link-active',
  navBar: 'nav-bar',
  settingsIcon: 'settings-icon',
  splashWrapper: 'splash-wrapper',
  tickIcon: 'tick-icon',
  vegaIcon: 'vega-icon',
  vega: 'vega',
  walletIcon: 'wallet-icon',
  connectionsIcon: 'connections-icon',
};

export default locators;
