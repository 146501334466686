import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const Xau = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg {...props} viewBox="0 0 122.88 110.41">
      <style type="text/css">
        {`.xau-0{fill-rule:evenodd;clip-rule:evenodd;fill:#E3B600;}
	.xau-1{fill-rule:evenodd;clip-rule:evenodd;fill:#E6BD00;}
.xau-2{fill-rule:evenodd;clip-rule:evenodd;fill:#FAC900;}`}
      </style>
      <g>
        <path
          className="xau-2"
          d="M32.46,68.83l8.47-29.8c0.31-1.09,1.3-1.8,2.38-1.8l0,0h36.11c1.2,0,2.2,0.86,2.43,1.99l8.69,29.76 c0.38,1.31-0.37,2.67-1.68,3.06c-0.23,0.07-0.46,0.1-0.69,0.1v0.01H34.79c-1.37,0-2.47-1.11-2.47-2.47 C32.31,69.36,32.36,69.09,32.46,68.83L32.46,68.83L32.46,68.83z"
        />
        <path
          className="xau-2"
          d="M83.81,2.41l-0.49,0.41c3.28,4.03,4.64,8.78,4.08,14.25c-0.56,5.47-2.86,9.84-6.89,13.12l0.41,0.49 c4.03-3.28,8.78-4.65,14.26-4.07c5.47,0.56,9.84,2.87,13.12,6.89l0.49-0.41c-3.28-4.03-4.65-8.78-4.08-14.25 c0.56-5.47,2.86-9.84,6.89-13.12l-0.42-0.5c-4.03,3.28-8.78,4.64-14.25,4.08C91.46,8.74,87.08,6.44,83.81,2.41L83.81,2.41 L83.81,2.41L83.81,2.41z"
        />
        <path
          className="xau-2"
          d="M39.63,0l-0.27,0.22c1.79,2.2,2.54,4.8,2.23,7.79c-0.3,2.99-1.57,5.38-3.77,7.17l0.22,0.27 c2.2-1.8,4.8-2.54,7.8-2.22c3,0.31,5.39,1.57,7.17,3.76l0.27-0.22c-1.8-2.2-2.54-4.8-2.23-7.79c0.3-2.99,1.57-5.38,3.77-7.17 L54.6,1.53c-2.2,1.79-4.8,2.54-7.79,2.23C43.82,3.46,41.42,2.2,39.63,0L39.63,0L39.63,0L39.63,0z"
        />
        <path
          className="xau-2"
          d="M0.14,107.11l8.47-29.8c0.31-1.09,1.3-1.81,2.38-1.81l0,0h36.11c1.21,0,2.2,0.86,2.43,1.99l8.69,29.76 c0.38,1.31-0.37,2.68-1.68,3.06c-0.23,0.07-0.46,0.1-0.69,0.1v0.01H2.48c-1.37,0-2.48-1.11-2.48-2.47 C0,107.65,0.05,107.38,0.14,107.11L0.14,107.11L0.14,107.11z"
        />
        <path
          className="xau-2"
          d="M64.71,107.11l8.46-29.8c0.31-1.09,1.3-1.81,2.38-1.81l0,0h36.11c1.2,0,2.2,0.86,2.43,1.99l8.7,29.76 c0.38,1.31-0.37,2.68-1.68,3.06c-0.23,0.07-0.46,0.1-0.69,0.1v0.01H67.04c-1.37,0-2.47-1.11-2.47-2.47 C64.56,107.65,64.61,107.38,64.71,107.11L64.71,107.11L64.71,107.11z"
        />
        <path
          className="xau-1"
          d="M32.4,68.9l8.47-29.8c0.31-1.09,1.3-1.81,2.38-1.81l0,0h36.11c1.2,0,2.2,0.86,2.43,1.99l0.14,0.47h-34.9l0,0 c-1.08,0-2.07,0.72-2.38,1.81l-8.47,29.8l0,0c-0.1,0.26-0.14,0.54-0.14,0.83v0.02h-1.29c-1.37,0-2.47-1.11-2.47-2.47 C32.25,69.43,32.3,69.15,32.4,68.9L32.4,68.9L32.4,68.9z"
        />
        <path
          className="xau-0"
          d="M0.14,107.11l8.47-29.8c0.31-1.09,1.3-1.8,2.38-1.8l0,0h36.11c1.2,0,2.2,0.86,2.43,1.99l0.14,0.46h-34.9l0,0 c-1.08,0-2.07,0.72-2.38,1.8l-8.47,29.8l0,0c-0.1,0.26-0.14,0.54-0.14,0.83v0.02H2.47c-1.37,0-2.47-1.11-2.47-2.47 C0,107.64,0.05,107.37,0.14,107.11L0.14,107.11L0.14,107.11z"
        />
        <path
          className="xau-1"
          d="M0.14,107.11l8.47-29.8c0.31-1.09,1.3-1.8,2.38-1.8l0,0h36.11c1.2,0,2.2,0.86,2.43,1.99l0.14,0.46h-34.9l0,0 c-1.08,0-2.07,0.72-2.38,1.8l-8.47,29.8l0,0c-0.1,0.26-0.14,0.54-0.14,0.83v0.02H2.47c-1.37,0-2.47-1.11-2.47-2.47 C0,107.64,0.05,107.37,0.14,107.11L0.14,107.11L0.14,107.11z"
        />
        <path
          className="xau-1"
          d="M64.71,107.11l8.47-29.8c0.31-1.09,1.3-1.8,2.38-1.8l0,0h36.11c1.21,0,2.2,0.86,2.43,1.99l0.14,0.46H79.32l0,0 c-1.08,0-2.07,0.72-2.38,1.8l-8.47,29.8l0,0c-0.1,0.26-0.14,0.54-0.14,0.83v0.02h-1.29c-1.37,0-2.47-1.11-2.47-2.47 C64.56,107.64,64.61,107.37,64.71,107.11L64.71,107.11L64.71,107.11z"
        />
        <path
          className="xau-2"
          d="M6.74,32.01l-0.27,0.22c1.79,2.2,2.54,4.8,2.23,7.79C8.4,43,7.13,45.39,4.93,47.19l0.22,0.27 c2.2-1.8,4.8-2.54,7.8-2.22c3,0.31,5.39,1.57,7.17,3.76l0.27-0.22c-1.8-2.2-2.54-4.8-2.23-7.79c0.3-2.99,1.57-5.38,3.77-7.17 l-0.22-0.27c-2.2,1.79-4.8,2.54-7.79,2.23C10.92,35.47,8.53,34.21,6.74,32.01L6.74,32.01L6.74,32.01L6.74,32.01z"
        />
      </g>
    </EmblemSvg>
  );
};
