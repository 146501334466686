import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const Aud = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg
      {...props}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 511.995"
    >
      <path
        fill="#ECCA43"
        fillRule="nonzero"
        d="M256 0c70.685 0 134.689 28.659 181.015 74.984C483.341 121.306 512 185.311 512 256c0 70.684-28.659 134.689-74.985 181.015-46.326 46.322-110.33 74.98-181.015 74.98-70.685 0-134.689-28.658-181.015-74.98C28.659 390.689 0 326.684 0 256c0-70.689 28.659-134.694 74.985-181.016C121.307 28.659 185.311 0 256 0z"
      />
      <ellipse fill="#F7E259" cx="256" cy="255.998" rx="250.992" ry="250.991" />
      <path
        fill="#F8D548"
        d="M503.753 215.692A252.691 252.691 0 01506.989 256c0 138.614-112.371 250.988-250.989 250.988S5.007 394.614 5.007 256c0-21.858 2.801-43.056 8.051-63.271l246.435 183.476 244.26-160.513z"
      />
      <path
        fill="#D7925B"
        fillRule="nonzero"
        d="M256 58.922c54.414 0 103.688 22.061 139.353 57.725 35.664 35.661 57.725 84.935 57.725 139.349 0 54.414-22.061 103.688-57.725 139.352-35.665 35.664-84.939 57.726-139.353 57.726-54.414 0-103.688-22.062-139.349-57.726-35.664-35.664-57.725-84.938-57.725-139.352s22.061-103.688 57.725-139.349C152.312 80.983 201.586 58.922 256 58.922z"
      />
      <circle fill="#EDA140" cx="256.001" cy="255.997" r="192.069" />
      <path
        fill="#C26A34"
        d="M230.461 325.132l-5.798-25.577h-36.358l-5.858 25.577h-37.298l38.3-134.266h45.257l38.734 134.266h-36.979zm81.874-1.639l-1.601-.099c-3.701-.22-7.348-.572-10.94-1.051a234.27 234.27 0 01-10.319-1.606 128.434 128.434 0 01-9.587-2.107v-27.188c3.993.352 8.328.638 12.965.88 4.642.236 9.361.412 14.124.528 4.764.11 9.192.176 13.306.176.281-.006.572.005.858.005 2.971 0 5.941-.302 8.85-.886a11.77 11.77 0 005.803-2.859 7.119 7.119 0 001.948-4.89c0-.127-.006-.259-.011-.391v-2.063c.005-.143.011-.28.011-.429a6.65 6.65 0 00-2.751-5.39 10.773 10.773 0 00-6.171-1.942h-9.609c-13.647 0-23.993-2.992-31.039-8.999-7.046-6.006-10.561-16.055-10.545-30.164v-5.819c0-12.932 3.889-22.613 11.65-29.026 5.759-4.786 13.443-7.784 23.058-8.994v-13.844h19.043V190.8c2.403.121 4.796.319 6.98.555 4.796.528 9.466 1.189 13.745 1.937 4.285.748 8.157 1.6 11.562 2.381v27.189c-5.401-.457-11.49-.841-18.256-1.149-6.771-.308-12.926-.452-18.443-.435h-.533c-2.778 0-5.545.247-8.273.709a10.897 10.897 0 00-5.996 2.817 8.315 8.315 0 00-2.117 6.166v1.782c-.005.187-.022.374-.022.561a8.107 8.107 0 002.794 6.122 13.63 13.63 0 008.647 2.283h11.644a62.6 62.6 0 011.441-.022c6.65 0 13.218 1.611 19.104 4.692a30.665 30.665 0 0112.348 12.794 39.18 39.18 0 014.147 17.529c0 .242-.005.49-.005.715v5.831c0 11.171-1.942 19.697-5.82 25.583a28.554 28.554 0 01-16.583 11.897 81.782 81.782 0 01-16.409 2.772v15.148l-18.998-.005v-15.159zm-93.909-52.486l-5.61-26.044c-.595-2.888-1.354-6.21-2.195-10a643.843 643.843 0 01-2.443-11.408c-.753-3.823-1.347-7.145-1.787-9.967a347.674 347.674 0 01-1.694 9.62c-.721 3.697-1.441 7.432-2.255 11.189a442.083 442.083 0 01-2.289 10.373l-5.704 26.237h23.977z"
      />
      <path
        fill="#F3DC6B"
        d="M224.568 319.244l-5.797-25.577h-36.358l-5.859 25.577h-37.298l38.3-134.265h45.257l38.735 134.265h-36.98zm81.88-1.639l-1.601-.099a150.6 150.6 0 01-10.94-1.05 232.525 232.525 0 01-10.319-1.607 128.072 128.072 0 01-9.588-2.106v-27.189c3.994.352 8.328.638 12.965.88 4.643.237 9.362.413 14.125.528 4.764.11 9.192.176 13.306.176.28-.005.572.006.858.006 2.97 0 5.941-.303 8.85-.886a11.772 11.772 0 005.803-2.86 7.114 7.114 0 001.936-5.28v-2.063c.006-.143.011-.281.011-.429a6.652 6.652 0 00-2.75-5.39 10.768 10.768 0 00-6.171-1.942H313.323c-13.646 0-23.993-2.993-31.039-8.999-7.046-6.006-10.561-16.056-10.544-30.164v-5.82c0-12.931 3.889-22.612 11.65-29.026 5.759-4.785 13.443-7.783 23.058-8.993v-13.844h19.042v13.464c2.404.121 4.797.319 6.98.556 4.797.528 9.467 1.188 13.746 1.936 4.285.748 8.157 1.601 11.562 2.382v27.189c-5.402-.457-11.49-.842-18.256-1.15-6.771-.308-12.926-.451-18.443-.434h-.534c-2.777 0-5.544.247-8.272.709a10.892 10.892 0 00-5.996 2.816 8.314 8.314 0 00-2.118 6.166v1.782c-.005.187-.021.375-.021.562a8.105 8.105 0 002.794 6.121 13.63 13.63 0 008.647 2.283h11.644c.467-.011.968-.022 1.441-.022 6.65 0 13.218 1.612 19.103 4.692a30.662 30.662 0 0112.349 12.794 39.195 39.195 0 014.147 17.53c0 .242-.006.489-.006.715v5.83c0 11.172-1.941 19.698-5.819 25.583a28.549 28.549 0 01-16.584 11.897 81.774 81.774 0 01-16.408 2.773v15.148l-18.998-.006v-15.159zm-93.915-52.485l-5.61-26.045c-.595-2.887-1.354-6.209-2.195-9.999a635.023 635.023 0 01-2.442-11.408c-.754-3.823-1.348-7.145-1.788-9.967a348.334 348.334 0 01-1.694 9.62c-.721 3.696-1.441 7.431-2.256 11.188a437.514 437.514 0 01-2.288 10.374l-5.703 26.237h23.976z"
      />
    </EmblemSvg>
  );
};
