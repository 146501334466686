export const IconStreak = ({ size = 14 }: { size: number }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14">
      <path d="M5.999 4H3.99902V5.99997H5.999V4Z" />
      <path d="M7.999 2.0001V0.00012207H5.99902V2.0001V4.00007H7.999V2.0001Z" />
      <path d="M3.99897 6H-0.000976562V7.99997H3.99897V6Z" />
      <path d="M5.999 7.99988H3.99902V9.99985H5.999V7.99988Z" />
      <path d="M7.999 9.99994H5.99902V13.9999H7.999V9.99994Z" />
      <path d="M9.999 7.99988H7.99902V9.99985H9.999V7.99988Z" />
      <path d="M13.999 6H9.99902V7.99997H13.999V6Z" />
      <path d="M9.999 4H7.99902V5.99997H9.999V4Z" />
    </svg>
  );
};
