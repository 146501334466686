export const IconMan = ({ size = 14 }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M8.00016 7.99996C9.47292 7.99996 10.6668 6.80605 10.6668 5.33329C10.6668 3.86053 9.47292 2.66663 8.00016 2.66663C6.5274 2.66663 5.3335 3.86053 5.3335 5.33329C5.3335 6.80605 6.5274 7.99996 8.00016 7.99996Z"
        stroke="#DCDEE3"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 9.33333C5.79119 9.33333 4.00033 11.1242 4.00033 13.3333V13.8667C4.00033 13.9403 3.94063 14 3.86699 14H2.80033C2.72669 14 2.66699 13.9403 2.66699 13.8667V13.3333C2.66699 10.3878 5.05481 8 8.00033 8C10.9458 8 13.3337 10.3878 13.3337 13.3333V13.8667C13.3337 13.9403 13.274 14 13.2003 14H12.1337C12.06 14 12.0003 13.9403 12.0003 13.8667V13.3333C12.0003 11.1242 10.2095 9.33333 8.00033 9.33333Z"
        fill="#DCDEE3"
        className="fill-current"
      />
    </svg>
  );
};
