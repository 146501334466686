import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const GoldEur = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg
      {...props}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 511.995"
    >
      <path
        fill="#ECCA43"
        fillRule="nonzero"
        d="M256 0c70.685 0 134.689 28.659 181.015 74.984C483.341 121.306 512 185.311 512 256c0 70.684-28.659 134.689-74.985 181.015-46.326 46.322-110.33 74.98-181.015 74.98-70.685 0-134.689-28.658-181.015-74.98C28.659 390.689 0 326.684 0 256c0-70.689 28.659-134.694 74.985-181.016C121.307 28.659 185.311 0 256 0z"
      />
      <path
        fill="#F7E259"
        d="M256 5.007c138.618 0 250.993 112.374 250.993 250.993 0 138.614-112.375 250.988-250.993 250.988S5.007 394.614 5.007 256C5.007 117.381 117.382 5.007 256 5.007z"
      />
      <path
        fill="#F8D548"
        d="M503.753 215.692A252.691 252.691 0 01506.989 256c0 138.614-112.371 250.988-250.989 250.988S5.007 394.614 5.007 256c0-21.858 2.801-43.056 8.051-63.271l246.435 183.476 244.26-160.513z"
      />
      <path
        fill="#D7925B"
        fillRule="nonzero"
        d="M256 58.922c54.414 0 103.688 22.061 139.353 57.725 35.664 35.661 57.725 84.935 57.725 139.349 0 54.414-22.061 103.688-57.725 139.352-35.665 35.664-84.939 57.726-139.353 57.726-54.414 0-103.688-22.062-139.349-57.726-35.664-35.664-57.725-84.938-57.725-139.352s22.061-103.688 57.725-139.349C152.312 80.983 201.586 58.922 256 58.922z"
      />
      <path
        fill="#EDA140"
        d="M256 63.929c106.076 0 192.071 85.994 192.071 192.067 0 106.076-85.995 192.071-192.071 192.071-106.073 0-192.067-85.995-192.067-192.071 0-106.073 85.994-192.067 192.067-192.067z"
      />
      <path
        fill="#C26A34"
        fillRule="nonzero"
        d="M283.676 265.691l-5.946 24.804h-41.341c5.342 16.909 17.001 25.363 34.838 25.363 19.602 0 37.951-6.318 54.998-18.953v50.725c-14.167 9.662-31.261 14.493-51.282 14.493-32.376 0-56.345-7.386-71.859-22.157-15.469-14.772-24.944-31.262-28.429-49.471h-24.619l6.457-24.804h15.051c-.186-1.347-.279-3.763-.279-7.2 0-3.624.093-6.225.279-7.85h-18.163l6.457-24.805h14.817c11.799-46.637 44.222-69.956 97.222-69.956 17.327 0 34.885 4.646 52.676 13.936l-12.263 49.331c-12.356-11.334-25.27-17.001-38.694-17.001-17.605 0-29.543 7.897-35.72 23.69h49.144l-5.945 24.805h-47.938c-.185 1.811-.278 4.412-.278 7.85v7.2h50.817z"
      />
      <path
        fill="#F3DC6B"
        fillRule="nonzero"
        d="M277.67 259.684l-5.946 24.805h-41.341c5.341 16.908 17 25.362 34.838 25.362 19.602 0 37.95-6.317 54.998-18.952v50.725c-14.168 9.662-31.262 14.493-51.282 14.493-32.377 0-56.345-7.386-71.86-22.158-15.468-14.771-24.944-31.261-28.428-49.47H144.03l6.457-24.805h15.05c-.186-1.347-.279-3.762-.279-7.2 0-3.623.093-6.224.279-7.85h-18.162l6.456-24.805h14.818c11.799-46.637 44.221-69.955 97.222-69.955 17.326 0 34.885 4.645 52.676 13.935l-12.263 49.331c-12.356-11.334-25.27-17.001-38.694-17.001-17.605 0-29.543 7.897-35.721 23.69h49.145l-5.946 24.805h-47.937c-.186 1.812-.279 4.413-.279 7.85v7.2h50.818z"
      />
    </EmblemSvg>
  );
};
