export const Routes = {
  HOME: '/',
  RESTRICTED: '/restricted',
  TX: 'txs',
  BLOCKS: 'blocks',
  PARTIES: 'parties',
  VALIDATORS: 'validators',
  ASSETS: 'assets',
  GENESIS: 'genesis',
  GOVERNANCE: 'governance',
  MARKETS: 'markets',
  ORACLES: 'oracles',
  NETWORK_PARAMETERS: 'network-parameters',
  DISCLAIMER: 'disclaimer',
  TREASURY: 'treasury',
};
