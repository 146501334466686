import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const Wheat = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg {...props} viewBox="0 0 122.88 115.77">
      <style type="text/css">{`
	.wheat-0{fill-rule:evenodd;clip-rule:evenodd;fill:#F09418;}
	.wheat-1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFD203;}
`}</style>
      <g>
        <path
          className="wheat-0"
          d="M61.6,18.45C67.81,14.22,68.69,8.32,61.64,0C58.58,3.08,52.1,12.03,61.6,18.45L61.6,18.45L61.6,18.45z"
        />
        <path
          className="wheat-0"
          d="M88.73,95.86c0.4-0.98,1.51-1.45,2.49-1.05s1.45,1.51,1.05,2.49c-0.78,1.93-1.69,3.85-2.6,5.78
		c-1.72,3.64-3.45,7.31-4.35,11.2c-0.24,1.03-1.26,1.67-2.29,1.44c-1.03-0.24-1.67-1.26-1.44-2.29c0.99-4.3,2.81-8.16,4.62-11.98
		C87.09,99.59,87.96,97.75,88.73,95.86L88.73,95.86L88.73,95.86z"
        />
        <path
          className="wheat-0"
          d="M30.3,97.29c-0.4-0.98,0.08-2.09,1.05-2.49c0.98-0.4,2.09,0.08,2.49,1.05c0.77,1.9,1.64,3.74,2.51,5.58
		c1.74,3.69,3.5,7.41,4.52,11.55c0.25,1.02-0.38,2.06-1.41,2.3c-1.02,0.25-2.06-0.38-2.3-1.41c-0.92-3.74-2.6-7.29-4.26-10.82
		C31.99,101.14,31.09,99.22,30.3,97.29L30.3,97.29L30.3,97.29z"
        />
        <path
          className="wheat-0"
          d="M59.42,89.48c0-1.06,0.86-1.91,1.91-1.91c1.06,0,1.91,0.86,1.91,1.91v23.38c0,1.06-0.86,1.91-1.91,1.91
		c-1.06,0-1.91-0.86-1.91-1.91V89.48L59.42,89.48L59.42,89.48z"
        />
        <path
          className="wheat-0"
          d="M112.57,40.38c6.04-1.48,8.43-5.79,5.38-14.29C114.68,27.59,107.07,32.65,112.57,40.38L112.57,40.38
		L112.57,40.38z"
        />
        <path
          className="wheat-1"
          d="M110.99,48.4c4.95,2.3,7.74,2.1,11.89-5.38C117.74,42.52,112.75,40.9,110.99,48.4L110.99,48.4L110.99,48.4z
		 M108,47.29c-5.24-1.51-7.22-3.5-5.44-11.87C106.77,38.42,111.59,40.47,108,47.29L108,47.29L108,47.29z M91.62,90.97
		c-7.13-2.06-9.82-4.76-7.4-16.14C89.95,78.9,96.51,81.69,91.62,90.97L91.62,90.97L91.62,90.97z M96.13,78.92
		c-7.13-2.06-9.82-4.76-7.4-16.14C94.46,66.85,101.01,69.64,96.13,78.92L96.13,78.92L96.13,78.92z M100.56,67.11
		c-6.34-1.83-8.73-4.23-6.58-14.35C99.08,56.38,104.91,58.86,100.56,67.11L100.56,67.11L100.56,67.11z M104.51,56.61
		c-5.63-1.62-7.75-3.76-5.84-12.74C103.19,47.08,108.36,49.29,104.51,56.61L104.51,56.61L104.51,56.61z M94.69,92.11
		c6.73,3.13,10.53,2.85,16.18-7.33C103.87,84.1,97.09,81.91,94.69,92.11L94.69,92.11L94.69,92.11z M99.2,80.06
		c6.73,3.13,10.53,2.85,16.18-7.33C108.38,72.05,101.6,69.86,99.2,80.06L99.2,80.06L99.2,80.06z M103.6,68.24
		c5.99,2.78,9.36,2.54,14.38-6.51C111.76,61.12,105.73,59.17,103.6,68.24L103.6,68.24L103.6,68.24z M107.51,57.73
		c5.31,2.47,8.31,2.25,12.77-5.78C114.76,51.41,109.4,49.68,107.51,57.73L107.51,57.73L107.51,57.73z"
        />
        <path
          className="wheat-0"
          d="M10.31,40.07c-6.04-1.48-8.43-5.79-5.38-14.29C8.2,27.29,15.81,32.34,10.31,40.07L10.31,40.07L10.31,40.07z"
        />
        <path
          className="wheat-1"
          d="M11.89,48.1C6.94,50.4,4.15,50.19,0,42.71C5.14,42.21,10.13,40.6,11.89,48.1L11.89,48.1L11.89,48.1z
		 M31.26,90.66c7.13-2.06,9.82-4.76,7.4-16.14C32.93,78.59,26.37,81.39,31.26,90.66L31.26,90.66L31.26,90.66z M26.75,78.61
		c7.13-2.06,9.82-4.76,7.4-16.14C28.42,66.54,21.87,69.33,26.75,78.61L26.75,78.61L26.75,78.61z M22.32,66.8
		c6.34-1.83,8.73-4.23,6.58-14.35C23.8,56.07,17.97,58.55,22.32,66.8L22.32,66.8L22.32,66.8z M18.37,56.3
		c5.63-1.62,7.75-3.76,5.84-12.74C19.69,46.78,14.52,48.98,18.37,56.3L18.37,56.3L18.37,56.3z M14.88,46.98
		c5.24-1.51,7.22-3.5,5.44-11.87C16.11,38.11,11.29,40.16,14.88,46.98L14.88,46.98L14.88,46.98z M28.19,91.81
		c-6.73,3.13-10.53,2.85-16.18-7.33C19.01,83.8,25.79,81.6,28.19,91.81L28.19,91.81L28.19,91.81z M23.68,79.76
		c-6.73,3.13-10.53,2.85-16.18-7.33C14.5,71.75,21.28,69.55,23.68,79.76L23.68,79.76L23.68,79.76z M19.28,67.93
		c-5.99,2.78-9.36,2.54-14.38-6.51C11.12,60.81,17.15,58.86,19.28,67.93L19.28,67.93L19.28,67.93z M15.37,57.43
		c-5.31,2.47-8.31,2.25-12.77-5.78C8.12,51.1,13.48,49.37,15.37,57.43L15.37,57.43L15.37,57.43z"
        />
        <path
          className="wheat-1"
          d="M63.22,40.24c7.06,0.55,10.36-0.97,12.01-11.95C68.75,30.02,61.96,30.32,63.22,40.24L63.22,40.24L63.22,40.24z
		 M63.21,28.21c6.58,0.51,9.65-0.9,11.18-11.13C68.36,18.68,62.04,18.97,63.21,28.21L63.21,28.21L63.21,28.21z M59.31,84.6
		c-8.95,0.69-13.13-1.23-15.21-15.14C52.31,71.64,60.91,72.03,59.31,84.6L59.31,84.6L59.31,84.6z M59.31,69.04
		c-8.95,0.69-13.13-1.23-15.21-15.14C52.31,56.09,60.91,56.48,59.31,69.04L59.31,69.04L59.31,69.04z M59.33,53.8
		c-7.96,0.62-11.68-1.09-13.53-13.46C53.1,42.28,60.75,42.62,59.33,53.8L59.33,53.8L59.33,53.8z M59.35,40.24
		c-7.06,0.55-10.36-0.97-12.01-11.95C53.82,30.02,60.61,30.32,59.35,40.24L59.35,40.24L59.35,40.24z M59.36,28.21
		c-6.58,0.51-9.65-0.9-11.18-11.13C54.21,18.68,60.54,18.97,59.36,28.21L59.36,28.21L59.36,28.21z M63.27,84.6
		c8.95,0.69,13.13-1.23,15.21-15.14C70.27,71.64,61.66,72.03,63.27,84.6L63.27,84.6L63.27,84.6z M63.27,69.04
		c8.95,0.69,13.13-1.23,15.21-15.14C70.27,56.09,61.66,56.48,63.27,69.04L63.27,69.04L63.27,69.04z M63.24,53.8
		c7.96,0.62,11.68-1.09,13.53-13.46C69.47,42.28,61.82,42.62,63.24,53.8L63.24,53.8L63.24,53.8z"
        />
      </g>
    </EmblemSvg>
  );
};
