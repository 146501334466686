export const IconArrowTopRight = ({ size = 16 }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14">
      <path
        d="M 10.47,4.28
           C 10.47,4.28 10.47,11.49 10.47,11.49
             10.47,11.49 11.53,11.49 11.53,11.49
             11.53,11.49 11.53,2.47 11.53,2.47
             11.53,2.47 2.51,2.47 2.51,2.47
             2.51,2.47 2.51,3.53 2.51,3.53
             2.51,3.53 9.72,3.53 9.72,3.53
             9.72,3.53 2.14,11.12 2.14,11.12
             2.14,11.12 2.88,11.86 2.88,11.86
             2.88,11.86 10.47,4.28 10.47,4.28 Z
           M -37.55,0.64"
      />
    </svg>
  );
};
